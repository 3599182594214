import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { getCurrentUser } from "../../services/auth";
import ReferrerUser from "./referrer-user";
import ReferrerOrganization from "./referrer-organization";
import PagePadding from "../layout/page-padding";
import Assign from "../licenses/assign";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ReferrerHome() {
  const [value, setValue] = React.useState(0);
  const [isResponsible, setIsResponsible] = React.useState();
  const [assigned, setAssigned] = React.useState();

  useEffect(() => {
    const user = getCurrentUser();

    setIsResponsible(user.organization_responsible);
    setAssigned(user.licenses.find(license => license.license === 'referrer'));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {!assigned ? <Assign license="referrer" afterAssigned={() => setAssigned(true)} /> : (
        <>
          {isResponsible ? (
            <>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                >
                  <Tab label="Indicações da empresa" />
                  <Tab label="Minhas indicações" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}><ReferrerOrganization /></TabPanel>
              <TabPanel value={value} index={1}><ReferrerUser /></TabPanel>

            </>
          ) : <PagePadding><ReferrerUser /></PagePadding>}
        </>
      )}
    </>
  );
}