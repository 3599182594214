import { Container, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import PagePadding from "../layout/page-padding";
import AppsIcon from '@material-ui/icons/Apps';
import EventIcon from '@material-ui/icons/Event';
import UpdateIcon from '@material-ui/icons/Update';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LinkGatsby from 'gatsby-link';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    padding: 10,
    height: 250,
  },
  container: {
    paddingTop: 80,
  },
  icon: {
    width: 60,
    height: 60,
    color: '#5c5c5f'
  },
  title: {
    color: '#5c5c5f',
    textDecoration: 'none',
  }
}));

export default function InternalHome() {
  const classes = useStyles();

  const items = [
    { title: 'PROCESSOS', link: '/workflow/board', icon: <AppsIcon className={classes.icon} /> },
    { title: 'AGENDA', link: '/workflow/agenda', icon: <EventIcon className={classes.icon} /> },
    { title: 'ATIVIDADES', link: '/workflow/activities', icon: <UpdateIcon className={classes.icon} /> },
    { title: 'DASHBOARD', link: '/dashboard', icon: <EqualizerIcon className={classes.icon} /> },
  ];

  return (
    <PagePadding>
      <Container>
        <Grid container className={classes.root} spacing={2}>
          {items.map(item => {
            return (
              <Grid item lg={3} xl={3} xs={12} sm={6} md={6}>
                <Paper className={classes.paper} elevation={3}>
                  <div className={classes.container}>
                    <LinkGatsby to={item.link} style={{textDecoration: 'none'}}>
                      {item.icon}
                      <Typography className={classes.title} variant="overline" display="block" gutterBottom>{item.title}</Typography>
                    </LinkGatsby>
                  </div>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </PagePadding>
  );
}