import React, { useEffect, useState } from "react";
import ReferrerHome from "../components/home/referrer-home";
import InternalHome from "../components/home/internal-home";
import LayoutLogged from "../components/layout/logged";
import { getCurrentUser } from "../services/auth";


export default function Home() {
  const [isReferrer, setIsReferrer] = useState(false);
  const [isInternal, setIsInternal] = useState(false);

  useEffect(() => {
    const user = getCurrentUser();

    setIsReferrer(user?.roles?.includes('referrer'));
    setIsInternal(user?.permissions?.includes('process.view'));
  }, []);
  return (
    <LayoutLogged>
      {isReferrer && (<ReferrerHome />)}
      {isInternal && (<InternalHome />)}
    </LayoutLogged>
  );
}
